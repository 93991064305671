<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-6">
      <label for="form-name" class="form-label">Заголовок <sup>*</sup></label>
      <input
          id="form-name"
          @input="updateVModel('form_title')"
          v-model="form_title"
          type="text"
          class="form-control w-full"
          placeholder=""
          required
          autocomplete="off"
      >
    </div>

    <div class='col-span-6'>
      <label class='form-label'>Изображение <sup>*</sup></label>
      <UploadImage
          :image="imagePreview"
          v-model="form_image"
          @update="updateVModel('form_image')"
      ></UploadImage>
    </div>

    <div class='col-span-12'>
      <label class='form-label'>Описание</label>
      <CustomWyswig v-model="form_content" @update:modelValue="updateVModel('form_content')"/>
    </div>

  </div>
</template>

<script>
import CustomWyswig from '@/components/base/custom-wyswig/Main.vue';
import UploadImage from '@/components/upload-image/UploadImage';

export default {
  name: "DefaultBlock",
  components: {CustomWyswig, UploadImage},
  props: {
    form_title: String,
    form_image: {
      default: null
    },
    form_content: String,
  },
  data() {
    return {
      image: null,
      imagePreview: null
    };
  },
  computed: {},
  methods: {
    updateVModel(key) {
      this.$emit(`update:${key}`, this[key]);
    }
  },

  created() {
    if (this.form_image instanceof File) {
      this.imagePreview = window.URL.createObjectURL(new Blob([this.form_image]))
    } else {
      this.imagePreview = this.form_image;
    }
  },
  updated() {
    if (this.form_image instanceof File) {
      this.imagePreview = window.URL.createObjectURL(new Blob([this.form_image]))
    } else {
      this.imagePreview = this.form_image;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep{
    .room-card {
        height: 100%;
        display: flex;
        flex-direction: column;
        width: 440px;
        color: #fff;

        &-wrap {
            grid-column-gap: 38px;
            grid-row-gap: 126px;
        }

        &-box {
            height: 100%;
            font-family: "Gilroy", sans-serif;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: #fff;
            @apply col-span-12 sm:col-span-6 lg:col-span-4;

            & > div {
                height: 100%;
            }
        }

        &-img {
            position: absolute;
            top: -46px;
            left: 50%;
            width: 92px;
            height: 92px;
            margin-left: -46px;
            border-radius: 50%;
        }

        &-heading {
            background: #202020 url("/images/blocks-black-bg.png") no-repeat left center/cover;
            padding: 52px 30px 22px;
            text-align: center;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            overflow: hidden;
        }

        h3 {
            margin: 9px 0 0;
            font-weight: 900;
            font-size: 32px;
            line-height: 40px;
        }

        &-sub-content {
            padding: 26px 30px 28px;
            font-weight: 700;
            @apply bg-darker-200;
        }

        &-content {
            display: flex;
            flex-direction: column;
            flex: 1;
            padding: 21px 30px 60px;
            background: #2C2C2C;
            border-radius: 0 0 5px 5px;
            overflow: hidden;

            &-title {
                text-align: center;
                font-weight: 700;
            }
        }

        &-sub-content {
            & > * {
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &-sub-content,
        &-content {
            & > * {
                margin-bottom: 18px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &-content-link {
            display: block;
            color: #FF0000;
            transition: all .3s ease;
            text-decoration: underline;

            &:hover,
            &:focus {
                color: rgba(#FF0000, 0.9);
                text-decoration: none;
            }
        }

        &-content-btns {
            margin-top: auto;
            margin-bottom: 0;
        }

        .c-btn {
            display: block;
            max-width: 254px;
            width: 100%;
            margin: 18px auto 0;
            padding: 15px 36px;
            background-color: #fff;
            color: #000;
            text-transform: uppercase;
            font-size: 14px;
            line-height: 16px;

            &:hover,
            &:focus {
                background-color: #FF0000;
                color: #fff;
            }
        }
    }
}
</style>
