<template>
  <div>
    <h1 class="page__title">Страницы</h1>
    <div class="actions__container">
      <div>
        <router-link
          class="btn btn-primary w-40 inline-block mr-1 mb-2"
          :to="{ name: 'pageCreate' }"
        >
          Создать
        </router-link>
      </div>
    </div>

    <ContentSearch />

    <ContentTable
      :table-actions="tableActions"
      :headers="tableHeaders"
      :table-rows="tableData"
      :meta="pagination"
      :loading="loading"
      @edit="editAction"
      @delete="deleteAction"
    />

    <CrudModal
      :isOpen="modalOpen"
      :title="modalTitle"
      :type="modalType"
      :accept-callback="modalCallback"
      :modal-data="modalData"
      @update:isOpen="modalOpen = $event"
      @deleteModalAccepted="onDeleteModalAccepted"
    />
  </div>
</template>

<script>
  import { errorResponse } from '@/mixins/form/form-mixin.js';
  import ContentTable from '@/components/tables/ContentTable.vue';
  import CrudModal from '@/components/modals/CrudModal.vue';
  import ContentSearch from '@/components/base/ContentSearch.vue';

  export default {
    components: { ContentSearch, CrudModal, ContentTable },
    mixins: [errorResponse],
    data() {
      return {
        modalComponent: () => import('@/components/modals/ModalDelete.vue'),
        modalOpen: false,
        modalType: null,
        modalTitle: null,
        modalCallback: null,
        modalData: null,
        tableActions: [
          { name: 'edit', title: 'редактировать', icon: 'Edit2Icon', cbName: 'edit' },
          { name: 'delete', title: 'удалить', class: 'bg-red-400', icon: 'Trash2Icon', cbName: 'delete' },
        ],
        tableHeaders: [
          { field: 'id', label: '#' },
          { field: 'slug', label: 'Ссылка' },
          { field: 'title', label: 'Название' },
        ],
        loading: false,
        tableData: [],
        pagination: null,
      };
    },
    async created() {
      await this.fetchData(this.$route.query);
    },
    methods: {
      async fetchData(params) {
        try {
          this.loading = true;
          const { data } = await this.axios.get('/pages', { params });
          this.tableData = data.data;
          this.pagination = data.meta;
          this.loading = false;
        } catch (ex) {
          this.loading = false;
          console.log('cant fetch data: ' + ex);
        }
      },
      editAction(row) {
        this.$router.push({ name: 'pageEdit', params: { id: row.id } });
      },
      deleteAction(row) {
        this.modalData = row;
        this.modalType = 'delete';
        this.modalOpen = true;
      },
      onDeleteModalAccepted(data) {
        this.axios.delete('/pages/' + data.id)
          .then(res => {
            this.fetchData();
            this.modalOpen = false;
          })
          .catch(ex => {
            this.errorResponse(ex);
          });
      },
    },
    async beforeRouteUpdate(to, from, next) {
      await this.fetchData({ ...to.query });
      next();
    },
  };
</script>

<style></style>
