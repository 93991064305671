<template>
  <div>
    <h1 class="page__title">Настройки сайта</h1>
    <div class="actions__container">
      <div>
        <div class="items">
            <div v-for='(item, key) in data' :value='item.key' class="item">
                <div class="key">{{item.title ?? item.key}}:</div>
                <div class="value"><input type="text" v-model="item.value"></div>
            </div>
        </div>
      </div>
    </div>
      <div class="col-span-12 flex items-center justify-end mt-5">
          <button type="submit" class='btn btn-primary mb-2' @click.prevent="save()">Сохранить</button>
      </div>
  </div>
</template>

<script>
  import { errorResponse } from '@/mixins/form/form-mixin.js';
  import ContentTable from '@/components/tables/ContentTable.vue';
  import CrudModal from '@/components/modals/CrudModal.vue';
  import ContentSearch from '@/components/base/ContentSearch.vue';

  export default {
    components: { ContentSearch, CrudModal, ContentTable },
    mixins: [errorResponse],
    data() {
      return {
        modalComponent: () => import('@/components/modals/ModalDelete.vue'),
        loading: false,
        data: null
      };
    },
    async created() {
      await this.fetchData();
    },
    methods: {
      async fetchData() {
        try {
          this.loading = true;
          const { data } = await this.axios.get('/site-settings');
          this.data = data.data;
          this.loading = false;
        } catch (ex) {
          this.loading = false;
          console.log('cant fetch data: ' + ex);
        }
      },
        async save() {
            this.loading = true;

            this.axios.post('/site-settings', {'items': this.data})
                .then(res => {
                    this.$notify('Настройки сохранены');
                    this.loading = false;
                })
                .catch(ex => {
                    this.errorResponse(ex);
                });
        },
    },
  };
</script>

<style lang="scss" scoped>
    .items {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        .item {
            padding-bottom: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 45%;
            min-width: 150px;
            font-size: 20px;
            border-bottom: 1px solid #1c3faa;
            margin-right: 5%;
            margin-bottom: 20px;
        }

        .key {

        }

        input {
            padding: 5px;
            border: #dddddd 1px solid;
        }
    }

</style>
