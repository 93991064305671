<template>
  <div class="upload-image">
    <div class="input-wrap" @click="$refs[refKey].click">
      <slot name="text">
        <span class="btn btn-primary inline-block">Загрузить изображение</span>
      </slot>

      <input
        :ref="refKey"
        type="file"
        :accept="acceptExtensions"
        @change="uploadFile"
        class="input"
      >
    </div>
    <div class="preview" :class="{ 'active': previewImage }">
      <div v-if="previewImage" class="item">
        <slot name="image" :preview="previewImage">
          <img :src="previewImage" :alt="previewImage">
        </slot>
        <slot name="remove" remove="remove">
          <span @click="remove"><XCircleIcon/></span>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "UploadImage",
  data() {
    return {
      previewImage: null
    }
  },
  props: {
    refKey: {
      type: String,
      default: 'uploadImageInput',
    },
    image: String,
    modelValue: null,
    delete: Boolean,
    acceptExtensions: {
      type: String,
      default: 'image/jpeg, image/png, image/gif, image/gif',
    },
  },
  mounted() {
    this.fillPreviewImageOnInit();
  },
  watch: {
    image: function (val) {
      this.previewImage = val
    },
  },
  methods: {
    fillPreviewImageOnInit() {
      if (this.image) this.previewImage = this.image;
    },
    uploadFile(e) {
      this.$emit(`update:modelValue`, e.target.files[0])
      this.previewImage = window.URL.createObjectURL(e.target.files[0]);
      this.$emit(`update`)
    },
    remove() {
      if (this.image === this.previewImage) {
        this.$emit(`update:delete`, true);
      }
      window.URL.revokeObjectURL(this.previewImage);
      this.previewImage = null;
      this.$emit(`update:modelValue`, null);
      this.$emit(`update`)
    },
  },
}
</script>

<style scoped lang="scss">
  .upload-image {
    box-sizing: border-box;
    min-height: 150px;
    background: white;
    padding: 20px 20px;
    border: 1px dashed #cbd5e0;
  }

  .input-wrap {
    padding-bottom: 15px;
  }

  .input {
    visibility: hidden;
  }

  .preview {
    border-top: 1px solid #cbd5e0;
    height: auto;
    width: 100%;
    display: flex;

    .item {
      max-width: 100%;
      margin: 10px;
      position: relative;

      span {
        position: absolute;
        right: -5px;
        top: -5px;
        cursor: pointer;
        background-color: #fff;
        border-radius: 50%;
      }

      img {
        overflow: hidden;
        max-height: 260px;
      }
    }
  }
</style>
