<template>
    <CKEditor
        v-model="editorData"
        :editor="classicEditor"
        :config="editorConfig"
    />
</template>

<script>
import {computed, defineComponent} from 'vue';
import GeneralHtmlSupport from '@ckeditor/ckeditor5-html-support/src/generalhtmlsupport';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import UnderlinePlugin from '@ckeditor/ckeditor5-basic-styles/src/underline';
import StrikethroughPlugin from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import CodePlugin from '@ckeditor/ckeditor5-basic-styles/src/code';
import SubscriptPlugin from '@ckeditor/ckeditor5-basic-styles/src/subscript';
import SuperscriptPlugin from '@ckeditor/ckeditor5-basic-styles/src/superscript';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import ImagePlugin from '@ckeditor/ckeditor5-image/src/image';
import ImageUploadPlugin from '@ckeditor/ckeditor5-image/src/imageupload';
import CloudServicesPlugin from '@ckeditor/ckeditor5-cloud-services/src/cloudservices';
import Font from '@ckeditor/ckeditor5-font/src/font';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import HeadingButtonsUI from '@ckeditor/ckeditor5-heading/src/headingbuttonsui';
import Highlight from '@ckeditor/ckeditor5-highlight/src/highlight';
import SourceEditing from '@ckeditor/ckeditor5-source-editing/src/sourceediting';
import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter';
import {List} from '@ckeditor/ckeditor5-list';
import {Table, TableToolbar} from '@ckeditor/ckeditor5-table';
import { Base64UploadAdapter } from '@ckeditor/ckeditor5-upload';
import { ImageCaption, ImageStyle, ImageToolbar } from '@ckeditor/ckeditor5-image';
import { RemoveFormat } from '@ckeditor/ckeditor5-remove-format';

export default defineComponent({
    name: 'CustomWyswig',
    props: {
        modelValue: {
            type: null,
        },
        ckConfig: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    setup(props, context) {
        const editorData = computed({
            get: () => props.modelValue ? props.modelValue : '',
            set: (value) => context.emit('update:modelValue', value),
        });
        const classicEditor = ClassicEditor;
        const defaultConfig = {
            // cloudServices: {
            //   tokenUrl: '',
            //   uploadUrl: '',
            // },
            allowedContent: true,
            plugins: [
                Font,
                EssentialsPlugin,
                BoldPlugin,
                UnderlinePlugin,
                StrikethroughPlugin,
                ItalicPlugin,
                LinkPlugin,
                ParagraphPlugin,
                CodePlugin,
                SubscriptPlugin,
                SuperscriptPlugin,
                ImagePlugin,
                ImageUploadPlugin,
                CloudServicesPlugin,
                Heading,
                HeadingButtonsUI,
                Highlight,
                SourceEditing,
                SimpleUploadAdapter,
                GeneralHtmlSupport,
                List,
                Table,
                TableToolbar,
                Base64UploadAdapter,
                ImageToolbar, ImageCaption, ImageStyle,
                RemoveFormat
            ],
            toolbar: {
                items: [
                    'fontSize',
                    'fontColor',
                    'fontBackgroundColor',
                    'bold',
                    'italic',
                    'underline',
                    'strikethrough',
                    'code',
                    'subscript',
                    'superscript',
                    'link',
                    'bulletedList',
                    'numberedList',
                    'undo',
                    'redo',
                    'imageUpload',
                    'highlight',
                    'sourceEditing',
                    'insertTable',
                    'removeFormat'
                ],
            },
            htmlSupport: {
                allow: [
                    {
                        name: /.*/,
                        attributes: true,
                        classes: true,
                        styles: true
                    }
                ]
            },
            table: {
                contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
            },
            image: {
                toolbar: [
                    'imageStyle:inline',
                    'imageStyle:block',
                    'imageStyle:side',
                    '|',
                    'imageStyle:alignLeft',
                    'imageStyle:alignCenter',
                    'imageStyle:alignRight',
                    '|',
                    'toggleImageCaption',
                    'imageTextAlternative',
                ]
            },
            simpleUpload: {
                uploadUrl: 'http://localhost.localhost',
                withCredentials: true,
                headers: {
                    'X-CSRF-TOKEN': 'CSRF-Token',
                    Authorization: localStorage.getItem('access_token'),
                },
            },
        };
        const editorConfig = Object.assign({}, defaultConfig, props.config);

        const initDocumentEditor = editor => {
            const toolbarContainer = document.querySelector(
                '.document-editor__toolbar',
            );
            toolbarContainer?.appendChild(editor.ui.view.toolbar.element);
            const win = window;
            win.editor = editor;
        };

        return {
            classicEditor,
            editorConfig,
            editorData,
            initDocumentEditor,
        };
    },
});
</script>

<style scoped lang="scss">
::v-deep {
    ul {
        list-style: disc;
    }

    ol {
        list-style: decimal;
    }
}

</style>
