<template>
  <div>
    <h1 class='page__title' v-if='pageTitle'>Редактирование {{ pageTitle }} 111</h1>

    <div class="intro-y box p-5 mt-5">
      <form
        method="post"
        class="grid grid-cols-12 gap-6"
        :class="{ 'disabled-form': loading }"
      >
        <div
          v-if="loading"
          class="absolute z-100 left-0 top-0 w-full h-full flex items-center justify-center"
        >
          <Preloader/>
        </div>

          <div class="col-span-6">
              <label for="form-name" class="form-label">Название <sup>*</sup></label>
              <input
                  id="form-name"
                  v-model='form.title'
                  type="text"
                  class="form-control w-full"
                  placeholder=""
                  required
                  autocomplete="off"
              >
          </div>

          <div class="col-span-6">
              <label for="form-slug" class="form-label">Ссылка на страницу</label>
              <input
                  id="form-slug"
                  v-model='form.slug'
                  type="text"
                  class="form-control w-full"
                  placeholder=""
                  required
                  autocomplete="off"
              >
          </div>

        <div class="col-span-6">
          <label for="form-name" class="form-label">Название (кнопка в шапке сайта)</label>
          <input
              id="form-btn-title"
              v-model='form.btn_title'
              type="text"
              class="form-control w-full"
              placeholder=""
              required
              autocomplete="off"
          >
        </div>

        <div class="col-span-6">
          <label for="form-name" class="form-label">Ссылка (кнопка в шапке сайта) </label>
          <input
              id="form-btn-link"
              v-model='form.btn_link'
              type="text"
              class="form-control w-full"
              placeholder=""
              required
              autocomplete="off"
          >
        </div>

          <div class='col-span-12'>
              <label class='form-label'>Описание</label>
              <CustomWyswig v-model="form.content"/>
          </div>

          <div class="blocks-wrap col-span-12">
            <div class="title-btn flex justify-between items-center">
              <h2 class="text-3xl">Блоки</h2>
              <div class="add-block flex justify-between items-center mt-4 mb-4">
                <select
                    id="form-blocks"
                    class='form-select form-select-lg'
                    v-model='selectBlock'
                >
                  <option v-for='(type, key) in block_types' :value='key'>
                    {{ type }}
                  </option>
                </select>
                <button class='btn btn-primary ml-2' @click.prevent="addBlock(selectBlock)">Добавить блок</button>
              </div>
            </div>
            <div class="blocks">
              <div v-for="(block, key) in getBlocks" class="block" :class="'block-' + block.type" :key="key">
                <div class="head">
                  <div class="title">{{block_types[block.type]}}</div>
                  <div class="btn-wrap">
                    <div v-if="key != 0" @click="upBlock(key)" class="btn"><arrow-up-icon/></div>
                    <div v-if="key != (form.blocks.length -1)" @click="downBlock(key)" class="btn"><arrow-down-icon/></div>
                    <div @click="deleteBlock(key)" class="btn btn-delete"><trash-icon/></div>
                  </div>
                </div>
                <Component
                    :is="getComponentName(block.type)"
                    v-model:form_title="block.title"
                    v-model:form_image="block.image"
                    v-model:form_content="block.content"
                ></Component>
              </div>
            </div>
          </div>

        <div class="col-span-12 flex items-center justify-end mt-5">
          <router-link :to="{ name: 'pages' }" class="btn btn-secondary w-24 mr-2 mb-2">Отмена</router-link>
          <button type="submit" class='btn btn-primary mb-2 mr-2'  @click.prevent="save(false)">Сохранить и выйти</button>
          <button type="submit" class='btn btn-primary mb-2'  @click.prevent="save(true)">Сохранить</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import { formPreparation, errorResponse } from '@/mixins/form/form-mixin.js';
  import Preloader from '@/components/preloader/Main.vue';
  import CustomWyswig from '@/components/base/custom-wyswig/Main.vue';
  import DefaultBlock from "../../components/blocks/DefaultBlock";

  export default {
    name: 'Edit',
    components: { Preloader, CustomWyswig, DefaultBlock },
    mixins: [formPreparation, errorResponse],
    data() {
      return {
        loading: false,
        pageTitle: null,
        form: {
            title: null,
            slug: null,
            btn_title: null,
            btn_link: null,
            content: null,
            blocks: [],
        },
        block_types: [],
        selectBlock: 'default',
      };
    },
    async created() {
      await this.fetchData();
      await this.fetchPage();
    },
    computed: {
      getBlocks() {
        return this.form.blocks;
      }
    },
    methods: {
      async fetchData() {
        this.loading = true;

        try {
          const { data } = await this.axios.get('/pages/create');
          this.block_types = data.block_types;
          this.loading = false;
        } catch (ex) {
          this.loading = false;
          console.log('cant fetch block types: ' + ex);
        }
      },
      async fetchPage() {
        this.loading = true;

        try {
          const { data } = await this.axios.get(`/pages/${this.$route.params.id}/edit`);

          for (const [key, val] of Object.entries(data.data)) {
            this.form[key] = val;
          }

          this.pageTitle = data.data.title;
          this.loading = false;
        } catch (ex) {
          console.log('cant fetch page: ' + ex);
          this.loading = false;
          this.$router.push('/error-page');
        }
      },
      async save(flag) {
        this.loading = true;
        const formData = this.formPreparation();

        this.axios.post('/pages/' + this.$route.params.id, formData, {
          params: {
            _method: 'patch',
          },
        })
          .then(res => {
            if(flag){
              this.fetchPage();
              this.$notify('Запись добавлена');
            }else {
              this.$router.push({name: 'pages'});
            }
            this.loading = false;
          })
          .catch(ex => {
            this.errorResponse(ex);
          });
      },
        addBlock(type) {
            const block = {
                type: type,
                title: null,
                image: null,
                content: null,
            };

            if (type == 'room') {
                block.content = `
                    <div class="room-card">
                      <div class="room-card-heading">
                        <div class="room-card-heading-sub">Party network</div>
                        <h3 class="room-card-heading-title">Party poker</h3>
                      </div>

                      <div class="room-card-sub-content">
                        <div><span>Подключение</span></div>
                        <div>Рейкбек</div>
                      </div>

                      <div class="room-card-content">
                        <div class="room-card-content-title">Дополнительно</div>
                        <a href="/123" class="room-card-content-link">Бонус от gipsyteam</a>
                        <div>Party</div>
                        <div>Бонус</div>
                        <a href="123" class="room-card-content-link">Ref</a>
                        <div class="room-card-content-btns">
                         <a href="123" class="c-btn room-card-content-btn">Подробности</a>
                        </div>
                      </div>
                    </div>
                  `;
            }

            this.form.blocks.push(block);
        },
      getComponentName(blockType) {
        // if () {
        //
        // }

        return 'DefaultBlock';
      },
      deleteBlock(index) {
        this.form.blocks.splice(index, 1);
      },
      upBlock(index) {
        const new_index = (index-1);
        if (new_index >= this.form.blocks.length) {
          let k = new_index - this.form.blocks.length + 1;
          while (k--) {
            this.form.blocks.push(undefined);
          }
        }
        this.form.blocks.splice(new_index, 0, this.form.blocks.splice(index, 1)[0]);
      },
      downBlock(index) {
        const new_index = (index+1);
        if (new_index >= this.form.blocks.length) {
          let k = new_index - this.form.blocks.length + 1;
          while (k--) {
            this.form.blocks.push(undefined);
          }
        }
        this.form.blocks.splice(new_index, 0, this.form.blocks.splice(index, 1)[0]);
      }
    },
  };
</script>

<style lang="scss" scoped>
.blocks-wrap {
  padding-bottom: 50px;
  .add-block {
    width: 450px;
    button {
      width: 200px;
    }
  }

  .blocks .block {
    background-color: rgb(204 207 124 / 15%);
    padding: 0 10px 30px 10px;
    //border-bottom: 2px solid #1c3faa;
    margin-bottom: 30px;
    &:nth-of-type(2n) {
      background-color: #fff;
    }

    .head {
      padding: 15px 10px;
      font-size: 18px;
      border-bottom: 2px solid #1c3faa;
      margin-bottom: 25px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .btn {
        background-color: #98b0ff;
        margin-left: 7px;
      }
      .btn-delete {
        background-color: #ff7f7f;
      }
    }
  }
}
::v-deep {
  .ck-content {
    ol, ul {
      font-family: "Gilroy", sans-serif;
      @apply max-w-md w-full font-medium text-[20px] leading-[26px];
    }

    ul {
      @apply space-y-1 list-disc list-inside;
    }

    ol {
      list-style: none;
      counter-reset: counterName;
      @apply space-y-1;

      li {
        position: relative;
        padding-left: 58px;
        counter-increment: counterName;
        margin-bottom: 21px !important;

        &:last-of-type {
          margin-bottom: 0 !important;
        }

        &:before {
          content: counter(counterName);
          position: absolute;
          left: 0;
          top: -2px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 34px;
          height: 34px;
          background-color: #F1F1F1;
          color: #202020;
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          border-radius: 50%;
        }
      }
      p {
        font-size: 20px;
        line-height: 23px;
      }
    }
    a {
      text-decoration: underline;
    }
  }
}
</style>
